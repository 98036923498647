<template>
  <v-chip
    small
    :color="stringColor(resistance.name.en)"
    v-text="resistance.name[$i18n.locale] || resistance.name.en"
  />
</template>
<script>
  export default {
    props: {
      resistance: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>
